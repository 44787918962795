<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分充值审核</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item style="display:flex;" label="提交时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-select  v-model="seachForm.status" clearable placeholder="请选择状态">
							<el-option v-for="item in enterOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.enterpriseName" placeholder="请输入企业用户" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="sumbitMore()">批量审核</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
                <el-table-column type="selection" width="55" :selectable='checkboxSelect' ></el-table-column>
				<el-table-column prop="createTime" label="提交时间" ></el-table-column>
				<el-table-column prop="enterpriseName" label="企业会员" ></el-table-column>
				<el-table-column prop="transferAmount" label="对公转账金额（元）" ></el-table-column>
				<el-table-column prop="corVoucher" label="对公凭证" >
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="showPreview(scope.$index, data)" type="text" size="small">
							预览
						</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="accountIntegral" label="到账积分" ></el-table-column>
				<el-table-column prop="status" label="状态" >
                    <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '未审核' : scope.row.status == 1 ? '审核通过' : '审核拒绝'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="checkTime" label="审核时间" ></el-table-column>
				<el-table-column prop="checkRemark" label="审核说明" ></el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" :disabled="scope.row.status != 0" type="text" size="small">
							审核
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 单量审核 -->
		<el-dialog :visible.sync="dialogVisible" width="600px" :before-close="handleClose" title="审核">
			<el-form class="form_box" label-position='right' label-width="70px" size="small">
                <el-form-item label="状态：">
					<el-radio-group v-model="checkStatus">
						<el-radio label="1">审核通过</el-radio>
						<el-radio label="2">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：">
                    <el-input type="textarea" :rows="4" v-model="explain" placeholder="请输入审核说明" style="width: 100%"></el-input>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose()">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitForm()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

        <!-- 批量审核 -->
		<el-dialog :visible.sync="moreAudit" width="500px" :before-close="handleClose" title="批量审核">
			<el-form class="form_box" label-position='right' label-width="70px" size="small">
                <h3 style="text-align:center;">此操作为批量审核操作，请谨慎使用</h3>
                <el-form-item label="状态：" style="margin:30px 0;">
					<el-radio-group v-model="checkStatus">
						<el-radio label="1">审核通过</el-radio>
						<el-radio label="2">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：">
                    <el-input type="textarea" :rows="4" v-model="explain" placeholder="请输入审核说明" style="width: 100%"></el-input>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose()">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitFormMore()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 对公凭证 -->
		<el-dialog :visible.sync="previewShow"  width="600px" title="对公凭证预览">
            <img :src="previewUrl" alt=""  class="avatar" style="display:block;margin:0 auto;width:auto;">
        </el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		integralrechargeAuditList,integralrechargeAuditUpdate 
	} from "@/api/platform/index";
	export default {
		name: "splitaccountAuditList",
		data() {
			return {
				image_url: imageUrl,
                checkStatus:'1',
                seachForm:{
					enterpriseName:'',
					status:'',
					beginTime:'',
					endTime:'',
				},
				enterOptions: [{label: '全部',value: ''},{label: '审核中',value: 0},{label: '审核通过',value: 1},{label: '审核拒绝',value: 2}],
				dialogVisible: false,
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                moreAudit:false,
				dateBetween:'',
				userName:'',
				explain:'',
				id:'',
				previewUrl:'',
				previewShow:false
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integralrechargeAuditList({
                    beginTime: this.seachForm.beginTime,
                    endTime:this.seachForm.endTime,
					enterpriseName:this.seachForm.enterpriseName,
					status:this.seachForm.status,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			// 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.seachForm.beginTime = '';
                    this.seachForm.endTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.seachForm.beginTime = datetime;
                this.seachForm.endTime = datetime1;
            },
            
            // 审核完的状态不能选
            checkboxSelect (row, rowIndex) {
                if (row.status == 0) {
                    return true // 不禁用
                }else{
                    return false // 不禁用
                }
            },

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},
			
			//关闭提醒
			handleClose(done) {
				this.dialogVisible = false;
				this.moreAudit = false;
				this.checkStatus = '1';
				this.id = '';
				this.explain = '';
			},

			//编辑按钮点击
			updataclick(i,tab){
				this.id = tab[i].id
				this.dialogVisible = true;
			},

			// 凭证弹出层
			showPreview(i,tab){
				this.previewUrl = tab[i].corVoucher != null ? tab[i].corVoucher : '';
				this.previewShow = true;
			},

            // 批量审核弹出层
            sumbitMore(){
                let that = this;
                if(that.$refs.elTable.selection.length > 0){
                    that.moreAudit = true;
                }else{
                    that.$message.warning('请先勾选要审核的内容');
                }
            },

			// 单审核确定
			submitForm(){
                let that = this;
				if(that.dialogVisible){
					if(that.explain != null && that.explain.length > 0){
						integralrechargeAuditUpdate({
							ids: that.id,
							status: Number(that.checkStatus),
							checkRemark: that.explain,
						}).then(res=>{
							if (res.code == 200) {
								that.$message.success('操作成功');
								
								that.dialogVisible = false
								that.checkStatus = '1';
								that.explain = '';
								that.id = '';
								that.page = 1
								that.list();
							} else {
								that.$message.error(res.msg);
							}
						}).catch(err => {
							that.$message.error(err.msg);
						})
					}else{
						that.$message.warning('审核说明不能为空');
					}
                }
			},

			// 批量审核确定
            submitFormMore(){
                let that = this;
                var arrIds = [];
                for(var i of that.$refs.elTable.selection){
                    arrIds.push(i.id);
                }
				if(that.explain != null && that.explain.length > 0){
					integralrechargeAuditUpdate({
						ids: arrIds.join(','),
						status: Number(that.checkStatus),
						checkRemark: that.explain,
					}).then(res=>{
						if (res.code == 200) {
							that.$message.success('操作成功');
							
							that.moreAudit = false;
							that.checkStatus = '1';
							that.explain = '';
							that.page = 1
							that.list();
						} else {
							that.$message.error(res.msg);
						}
					}).catch(err => {
						that.$message.error(err.msg);
					})
				}else{
					that.$message.warning('审核说明不能为空');
				}
            },
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:300px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
</style>
